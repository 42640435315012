const startSuspensionClockEvent = () => [
  { action: "copyEvent", value: "suspension", type: "to" },
  { action: "copyEvent", value: "card-clock", type: "from" },
  { action: "copyData", value: "personId", type: "suspension" },
  { action: "sendEvent", value: "edit", type: "main" },
  { action: "newEventId", value: null, type: "main" },
  {
    action: "saveData",
    value: "start",
    type: "subType",
  },
  {
    action: "clearData",
    value: ["options"],
    type: "main",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "suspension", type: "from" },
];

export const PunishmentPlayerSelect = {
  name: "punishment-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "foul.select-player", //TODO: is this correct?
  showOn: [{ state: "primary", value: "punishment-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "punishment-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: true,
        showCoach: true,
      },
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "suspension", type: "to" },
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "redCard", type: "eventType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "suspension", type: "from" },
          ],
          conditions: [
            {
              item: "eventCountForPerson",
              eventType: "suspension",
              count: 2,
              event: "main",
            },
            {
              item: "eventType",
              value: "suspension",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "saveData",
              value: [{ option: "coach", value: true }],
              type: "options",
            },
          ],
          conditions: [
            {
              item: "coach",
              value: true,
              operator: "is",
              event: "main",
            },
          ],
        },
        {
          actions: [
            {
              action: "saveData",
              value: [{ option: "bench", value: true }],
              type: "options",
            },
          ],
          conditions: [
            {
              item: "bench",
              value: true,
              operator: "is",
              event: "main",
            },
          ],
        },
        {
          actions: [
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "redCard", type: "eventType" },
            { action: "sendEvent", value: "new", type: "main" },

            ...startSuspensionClockEvent(),
          ],
          conditions: [
            {
              item: "eventType",
              value: "redCard",
              event: "main",
              operator: "is",
            },
          ],
          operator: "any",
        },
        {
          actions: [
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "yellowCard", type: "eventType" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "is",
            },
          ],
          operator: "any",
        },
        {
          actions: [
            { action: "copyEvent", value: "card", type: "to" },
            { action: "newEvent", value: null, type: "main" },
            { action: "clearData", value: ["personId"], type: "main" },
            {
              action: "saveData",
              value: "goalKeeperChange",
              type: "eventType",
            },
            {
              action: "saveData",
              value: [{ option: "emptyNet", value: true }],
              type: "options",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "card", type: "from" },
          ],
          conditions: [
            {
              item: "eventType",
              operator: "is",
              value: "redCard",
              event: "main",
            },
            {
              item: "goalkeeper",
              operator: "is",
              value: true,
              event: "main",
            },
          ],
        },
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            { action: "clearData", value: ["options"], type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "confirm-later-punishment-player",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "confirmLater",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "yellowCard", type: "eventType" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "is",
            },
          ],
          operator: "any",
        },
        {
          actions: [
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "redCard", type: "eventType" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "redCard",
              event: "main",
              operator: "is",
            },
          ],
          operator: "any",
        },
        {
          actions: [
            { action: "saveData", value: true, type: "flagged" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "clearData", value: ["options"], type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
      startEvent: false,
      startPlay: false,
    },
  ],
};
